.loginForm {
    width    : 90%;
    max-width: 400px;
    margin   : 100px auto 20px;
}

.topLeft,
.topRight {
    position: fixed;
    left    : -180px;
    top     : -110px;
    z-index : -1;
    width   : 500px;
}
.blur{
    filter: blur(3px);
}

.topRight {
    left : auto;
    right: -180px;
    width: 800px;
}

.invCredentials {
    color     : #fff;
    background: #9f1208;
    padding   : 20px;
    width     : 100%;
    display   : block;
    opacity   : 10.3;
}
input:-internal-autofill-selected,input:-webkit-autofill{ 
    background-color   : #272727 !important;
    -webkit-text-fill-color: #272727 !important;
}

.loginForm input {
    display      : block;
    width        : 100%;
    background   : #272727;
    padding      : 20px;
    color        : #fff;
    border       : 0;
    outline      : none !important;
    font-size    : 20px;
    border-radius: 10px;
    margin       : 0 0 20px;
}

.App-logo {
    margin  : 20px auto;
}
.appLogoHome{
    margin  : 10px auto;
}
.btnLogin {
    background   : #EBAE24;
    color        : #fff !important;
    width        : 275px;
    text-align   : center;
    padding      : 18px 6px;
    border       : 0;
    outline: none !important;
    border-radius: 27px;
    transition   : background-color 0.2s ease;
    margin-bottom: 40px;
    font-size    : 1.3rem;
    font-family  : 'Montserrat', Arial, sans-serif !important;
    font-weight  : 700;
    line-height  : 1;
    cursor       : pointer;
}

.btnLogin:hover,.btnLogin:focus,.btnLogin:active  {
    background: #EB5F24;
}