html {
  height: 100%;
}

body {
  height               : 100%;
  margin               : 0;
  background           : #2e0101;
  background-image     : linear-gradient(180deg, #2e0101, #000);
  background-repeat    : no-repeat;
  background-attachment: fixed;
}

* {
  color      : #fff;
  font-family: 'Roboto', sans-serif;
}

ul {
  list-style: none;
  margin    : 0;
  padding   : 0;
}

table {
  width: 100%;
}

.container {
  max-width: 1200px;
  margin   : 0 auto;
  padding  : 0 20px;
}

.col-md-8,
.col-md-4,
.row {
  display: inline-block;
  float  : left;
}


.row {
  width: 100%;
}

.col-md-8 {
  width: 66%;
}

.col-md-4 {
  width: 33%;
}

.header {
  margin-bottom     : 40px;
  background        : #000 !important;
  -moz-box-shadow   : 0px 4px 12px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0px 0px 12px rgb(0 0 0 / 40%);
  box-shadow        : 0px 4px 12px rgb(0 0 0 / 40%);
  border-radius     : 10px;
  margin-top        : 10px;
  padding           : 10px 20px;
}

.logoutBtn {
  width        : 15px;
  cursor       : pointer;
  background   : #272727;
  padding      : 10px;
  border-radius: 10px;
}

.topMenu {
  float: right;
}

.topMenu li {
  display       : inline-block;
  vertical-align: middle;
  padding       : 12px 10px;

}

.leaveBalance {
  background   : #272727;
  display      : block;
  right        : 0;
  padding      : 20px;
  border-radius: 10px;
}

.greyDiv {
  background        : rgba(62, 62, 62, 0.73);
  display           : block;
  padding           : 20px;
  margin            : 0 10px 30px;
  border-radius     : 10px;
  -moz-box-shadow   : 0px 4px 12px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0px 0px 12px rgb(0 0 0 / 40%);
  box-shadow        : 0px 4px 12px rgb(0 0 0 / 40%);
  border-bottom     : 2px solid #464646;
}
.lastRow {
  margin-bottom: 100px;
}

th {
  color: #eb5f24;
}

td,
th {
  padding   : 10px;
  text-align: left;
}

.gLabel {
  vertical-align  : middle;
  display         : inline-block;
  font-size       : 2rem;
  font-family     : 'Abel', sans-serif;
  margin          : 0;
  /* margin-bottom:15px; */
}

.separator {
  width     : 100%;
  margin    : 21px auto 0;
  height    : 2px;
  background: #404040;
  display   : block;
}

.calendar-logo {
  width         : 40px;
  vertical-align: middle;
  display       : inline-block;
  margin        : 0 10px;
}

.footer {
  position  : fixed;
  bottom    : 0;
  left      : 0;
  width     : 100%;
  text-align: center;
  padding   : 20px;
  background: #000;
  font-size : 0.8rem;
}

.applyLeave {
  background     : #444444;
  color          : #fff !important;
  width          : 275px;
  text-align     : center;
  padding        : 15px 0px;
  border         : 0;
  outline        : none !important;
  border-radius  : 27px;
  transition     : background-color 0.2s ease;
  margin         : 20px auto 10px;
  font-size      : 1.1rem;
  font-family    : 'Montserrat', Arial, sans-serif !important;
  font-weight    : 700;
  line-height    : 1;
  display        : block;
  cursor         : pointer;
  text-decoration: none !important;
}


.applyLeave:hover,
.applyLeave:focus,
.applyLeave:active {
  background: #EB5F24;
}

.loader {
  width  : 100px;
  display: block;
  margin : 10px auto;
}

@media only screen and (max-width: 900px) {
  .lastRow {
    margin-bottom: 30px;
  }
  .lastRowMob{
    margin-bottom: 100px !important;
  }
  .topLeft,
  .topRight {
    display: none;
  }

  .col-md-8,
  .col-md-4,
  .row {
    float: none;
    width: 100%;
  }
}